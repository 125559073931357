"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
function htmlId(id) {
  if (!id) return '';
  // Remove all characters that are not letters, numbers, hyphens, or underscores
  id = id.replace(/[^a-zA-Z0-9\-_]/g, '');
  // Ensure the id doesn't start with a number by adding a leading underscore if necessary
  if (/^\d/.test(id)) {
    id = '_' + id;
  }
  return id;
}
var _default = exports["default"] = htmlId;