import React from "react";
import {printComponent, ProgressBar, Input} from "nq-component";
import Id from "./Id";

function App() {
    const [position, setPosition] = React.useState("DELEGATE");
    const [objects, setObjects] = React.useState([]);
    const [progress, setProgress] = React.useState(0);
    const [frontImage, setFrontImage] = React.useState();
    const [backImage, setBackImage] = React.useState();
    const [primaryColor, setPrimaryColor] = React.useState("#000000");
    const [secondaryColor, setSecondaryColor] = React.useState("#ffffff");
    const ref = React.createRef();
    const onChangeIds = (event) => {
        const files = Array.from(event.target.files);
        setObjects(files.map(file => {
            const picture = URL.createObjectURL(file);
            const [first_name, last_name, middle_name, contact, email, position, city, province, region] = file.name.split(".").slice(0, -1).join(".").split(",");
            return {name: `${first_name} ${last_name}`, position, city, province, region, picture};
        }));
    };

    const onChangeFrontImage = (event) => {
        const file = event.target.files[0];
        setFrontImage(URL.createObjectURL(file));
    };

    const onChangeBackImage = (event) => {
        const file = event.target.files[0];
        setBackImage(URL.createObjectURL(file));
    };

    const onPrimaryColorChange = (value) => {
        setPrimaryColor(value);
    };

    const onSecondaryColorChange = (value) => {
        setSecondaryColor(value);
    };

    const onSubmitPrint = (event) => {
        event.preventDefault(); // Prevent default form submission
        printComponent(ref.current, {progress: v => setProgress((v * 100).toFixed())});
    };

    return (<>
        <div className="d-none">
            <div ref={ref}>
                {objects.map((object, index) => (
                    <Id
                        key={index}
                        frontImage={frontImage}
                        backImage={backImage}
                        primaryColor={primaryColor}
                        secondaryColor={secondaryColor}
                        object={object}
                        position={position}
                    />))}
            </div>
        </div>
        <div className="container mt-5">
            {progress > 0 && progress < 100 && (<div className="mt-1">
                <ProgressBar className="w-100" progress={progress}>
                    {progress}%
                </ProgressBar>
            </div>)}
            <h2>ID Upload Form</h2>
            <form onSubmit={onSubmitPrint}>
                <div className="row g-3">
                    <div className="col-md-4">
                        <label className="form-label fs-sm">Primary Color</label>
                        <Input
                            placeholder="Primary Color"
                            className="form-control-color"
                            type="color"
                            value={primaryColor}
                            onChange={onPrimaryColorChange}
                        />
                    </div>
                    <div className="col-md-4">
                        <label className="form-label fs-sm">Secondary Color</label>
                        <Input
                            placeholder="Secondary Color"
                            className="form-control-color"
                            type="color"
                            value={secondaryColor}
                            onChange={onSecondaryColorChange}
                        />
                    </div>

                    <div className="col-md-4">
                        <label className="form-label fs-sm">Position</label>
                        <Input
                            placeholder="Position"
                            type="string"
                            value={position}
                            onChange={setPosition}
                        />
                    </div>
                    <div className="col-md-4">
                        <label htmlFor="frontIdImage" className="form-label">Front ID Image</label>
                        <input
                            type="file"
                            className="form-control-file"
                            accept="image/*"
                            onChange={onChangeFrontImage}
                        />
                    </div>
                    <div className="col-md-4">
                        <label htmlFor="backIdImage" className="form-label">Back ID Image</label>
                        <input
                            type="file"
                            className="form-control-file"
                            accept="image/*"
                            onChange={onChangeBackImage}
                        />
                    </div>
                    <div className="col-md-4">
                        <label htmlFor="multipleFiles" className="form-label">Upload Additional Images</label>
                        <input
                            onChange={onChangeIds}
                            type="file"
                            className="form-control-file"
                            accept="image/*"
                            required
                            multiple
                        />
                    </div>
                </div>
                <button
                    type="submit"
                    className="btn btn-primary mt-3">
                    Print
                </button>
            </form>
        </div>
    </>);
}

export default App;
