"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
function objectToOption(object, indexes) {
  // first filter the index has value then if no value the id will be use
  return {
    label: indexes.filter(function (i) {
      return object[i];
    }).map(function (i) {
      return object[i];
    }).join(' ') || object.id,
    value: object.id,
    object: object
  };
}
var _default = exports["default"] = objectToOption;