"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var defaultProps = {
  keys: []
};
function OutputString(_ref) {
  var field = _ref.field,
    object = _ref.object,
    keys = _ref.keys;
  var value = object[field];
  if (value && typeof value !== 'object') {
    return value;
  }
  var values = [];
  keys.forEach(function (key) {
    var v = object[key];
    if (v && typeof v !== 'object') {
      values.push(v);
    }
  });
  return values.join(" ");
}
OutputString.defaultProps = defaultProps;
var _default = exports["default"] = OutputString;