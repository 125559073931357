"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
function getDistance(x1, y1, x2, y2) {
  var xDistance = x1 - x2;
  var yDistance = y1 - y2;
  var a = Math.pow(xDistance, 2);
  var b = Math.pow(yDistance, 2);
  var c = Math.sqrt(a + b);
  return c;
}
var _default = exports["default"] = getDistance;