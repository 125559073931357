import React from "react";
import {Image} from "nq-component";


const defaultProps = {
    // width: "54.22mm",
    // height: "85.7mm",
    width: "56mm",
    height: "87mm",
    primaryColor: "#ffffff",
    secondaryColor: "#ffffff",
    frontImage: "/front-id.png",
    backImage: "/back-id.png",
    object: {
        picture: "/placeholder-male.jpg",
        name: "DANI MARTINEZ",
        position: "DELEGATE",
        city: "",
        province: "",
        region: ""
    },
    position:"DELEGATE",
};

function Id({width, height, primaryColor, secondaryColor, frontImage, backImage, object,position}) {

    const renderFront = () => {
        const style = {
            width: "100%",
            height: "100%",
            backgroundImage: `url('${frontImage}'),linear-gradient(to right,${primaryColor}, ${secondaryColor || primaryColor})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "100% 100%",
            paddingTop: "102px"
        };
        return (<div
            className="d-flex flex-column px-3"
            style={style}>
            <div className="text-center">
                <Image
                    className="mx-auto"
                    width="90px"
                    height="90px"
                    fit
                    rounded
                    src={object.picture}/>
                <h6 className="fw-bold mb-1 mt-3 text-uppercase" style={{fontSize: "13px"}}>{object.name}</h6>
                <h6 className="fw-bold mb-0" style={{fontSize: "10px"}}>{position}</h6>
                <h6 className="fw-bold mb-0 text-uppercase" style={{fontSize: "10px"}}>{object.position}</h6>
                <h6 className="mb-0" style={{fontSize: "7px"}}>{object.city}</h6>
                <h6 className="mb-0" style={{fontSize: "7px"}}>{object.province}</h6>
                <h6 className="mb-0" style={{fontSize: "7px"}}>{object.region}</h6>
            </div>
        </div>)

    };

    const renderBack = () => {
        const style = {
            width: "100%",
            height: "100%",
            backgroundImage: `url('${backImage}'),linear-gradient(to right,${primaryColor}, ${secondaryColor || primaryColor})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "100% 100%",
            padding: "7px"
        };
        return (<div
            className="d-flex flex-column"
            style={style}>


        </div>);

    };
    const style = {
        width: width, height: height,
    }
    return (<>
        <div style={style}>
            {renderFront()}
        </div>
        <div style={style}>
            {renderBack()}
        </div>
    </>);
}

Id.defaultProps = defaultProps;
export default Id
